@import url("https://fonts.googleapis.com/css2?family=BenchNine&display=swap");
body {
  margin: 0;
  font-family: "BenchNine", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #26291e;
  color: whitesmoke;
}

body,
html,
#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4 {
  margin: 0.5rem 0 0.5rem 0;
}

h1 {
  font-size: 4rem;
  line-height: 4rem;
}
h2,
h3 {
  font-size: 2.5rem;
  line-height: 2.5rem;
}

h4 {
  font-size: 2rem;
}

p {
  font-size: 2rem;
}

.noise-wrapper {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  overflow: hidden;
  z-index: 10;
}

.noise-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.75) 100%
  );
}

.noise {
  position: absolute;
  top: -500px;
  right: -500px;
  bottom: -500px;
  left: -500px;
  background: transparent url(./noise.png) 0 0;
  background-size: 320px 320px;
  opacity: 0.35;
  animation: noise 1s steps(8, end) infinite both;
  pointer-events: none;
  z-index: -1;
}

@keyframes noise {
  0% {
    transform: translateX(0px, 0px);
  }
  10% {
    transform: translate(-100px, 100px);
  }
  20% {
    transform: translate(150px, -100px);
  }
  30% {
    transform: translate(-100px, 100px);
  }
  40% {
    transform: translate(100px, -150px);
  }
  50% {
    transform: translate(-100px, 200px);
  }
  60% {
    transform: translate(-200px, -100px);
  }
  70% {
    transform: translateY(50px, 100px);
  }
  80% {
    transform: translate(100px, -150px);
  }
  90% {
    transform: translate(0px, 200px);
  }
  100% {
    transform: translate(-100px, 100px);
  }
}
